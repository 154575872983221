import React from "react"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import SlideEpaviste from "../components/landing/slideEpaviste"
import Description from "../components/landing/description"
import Document from "../components/landing/document"
import Faq from "../components/landing/faq"
import Step from "../templates/department/components/step"
import Questions from "../templates/department/components/questions"

const CentreVhuAgree = () => (
  <Layout>
    <Seo
      title="Epaviste Dulin, centre VHU agréé pour traiter les véhicules hors d'usage en Ile de France"
      description="Formulaire de contact, déscription des formalités pour remorquer dans notre centre VHU votre véhicule par nos service"
    />
    <SlideEpaviste 
      backgroundImage={"centre"}
      para={"Pour convenir d'un rendez-vous afin de remorquer votre véhicule dans notre centre,"}
    />
    <Description
      titre={"Quel est le rôle de notre centre VHU agréé ?"}
      para={"Nous intervenons de manière gratuite en Ile de France pour faire enlever votre véhicule. À l’aide de nos camions de remorquages, nous venons prendre votre véhicule pour la transporter dans notre centre de recyclage agréé. Ce centre permet de traiter le véhicule afin d’éliminer ses éléments toxiques tel que l’huile du moteur, le caoutchouc, etc. Le service est entièrement gratuit, vous devez simplement nous fournir certains documents."}
    />
    <Step />
    <Document 
      titre={"Les documents à fournir pour remorquer votre véhicule dans notre centre VHU"}
    />
    <Questions 
      page={"centre"}
    />
    <Faq />
  </Layout>
)

export default CentreVhuAgree

